// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akupunktur-js": () => import("../src/pages/akupunktur.js" /* webpackChunkName: "component---src-pages-akupunktur-js" */),
  "component---src-pages-datenschutz-js": () => import("../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-psychosoziale-beratung-js": () => import("../src/pages/psychosoziale-beratung.js" /* webpackChunkName: "component---src-pages-psychosoziale-beratung-js" */),
  "component---src-pages-schwangerschaft-js": () => import("../src/pages/schwangerschaft.js" /* webpackChunkName: "component---src-pages-schwangerschaft-js" */),
  "component---src-pages-vita-js": () => import("../src/pages/vita.js" /* webpackChunkName: "component---src-pages-vita-js" */),
  "component---src-pages-wochenbett-js": () => import("../src/pages/wochenbett.js" /* webpackChunkName: "component---src-pages-wochenbett-js" */)
}

